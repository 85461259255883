import { Icons } from 'components/utils/Icons';
import { ModuleContext } from 'components/utils/module/ModuleContext';
import { StaffOnlyBadgeType, StaffOnlyWrapper } from 'components/utils/StaffOnlyWrapper';
import { FC, SVGProps, useContext, useMemo } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { hasViewOnlyPermission } from 'utils/permissions';
import {
  CARD_AMBIENT_LISTENING,
  CARD_API_BOX,
  CARD_CHECKBOXES,
  CARD_DATA_EXTRACTOR,
  CARD_GPT_BOX,
  CARD_GROUP,
  CARD_MESSAGE,
  CARD_MULTIPLE_CHOICE,
  CARD_NUMBERS,
  CARD_SECTION_DIVIDER,
  CARD_TEXT_INPUT,
  MODULE_TYPES,
} from '../../constants';

const chips = [
  {
    id: 'group',
    label: CARD_GROUP,
    icon: Icons.Group,
  },
  {
    id: 'multipleChoice',
    label: CARD_MULTIPLE_CHOICE,
    icon: Icons.MultipleChoice,
  },
  {
    id: 'numbers',
    label: CARD_NUMBERS,
    icon: Icons.Number,
  },
  {
    id: 'checkboxes',
    label: CARD_CHECKBOXES,
    icon: Icons.Checkbox,
  },
  {
    id: 'message',
    label: CARD_MESSAGE,
    icon: Icons.Message,
  },
  {
    id: 'sectionDivider',
    label: CARD_SECTION_DIVIDER,
    icon: Icons.SectionDivider,
  },
  {
    id: 'textInput',
    label: CARD_TEXT_INPUT,
    icon: Icons.TextInput,
  },
  {
    id: 'gptBox',
    label: CARD_GPT_BOX,
    icon: Icons.GPTBox,
  },
  {
    id: 'ambientListening',
    label: CARD_AMBIENT_LISTENING,
    icon: Icons.AmbientListening,
  },
  {
    id: 'dataExtractor',
    label: CARD_DATA_EXTRACTOR,
    icon: Icons.Variable,
    isBeta: true,
  },
  {
    id: 'apiBox',
    label: CARD_API_BOX,
    icon: Icons.Message,
    isBeta: true,
  },
];

export const chipsToShowInCalc = ['multipleChoice', 'checkboxes', 'numbers', 'apiBox', 'message'];

export const DraggableChips = () => {
  const { module } = useContext(ModuleContext);

  const chipsToShow = useMemo(() => {
    switch (module?.type) {
      case MODULE_TYPES.CALCULATOR:
        return chips.filter((chip) => chipsToShowInCalc.includes(chip.id));
      case MODULE_TYPES.ALGO:
        return chips;
      default:
        return [];
    }
  }, [module?.type]);

  return (
    <Droppable direction='horizontal' droppableId='ConversationItem' key='ConversationItem'>
      {(provided, snapshot) => {
        return (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className='sticky top-0 z-10 flex flex-wrap gap-2 bg-gray-100 p-6'
          >
            {chipsToShow.map((item, index) => {
              return (
                <Draggable
                  key={item.id}
                  draggableId={item.id}
                  index={index}
                  isDragDisabled={hasViewOnlyPermission()}
                >
                  {(provided) => {
                    return (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {item.isBeta ? (
                          <StaffOnlyWrapper type={StaffOnlyBadgeType.BETA}>
                            <DraggableChip label={item.label} Icon={item.icon} />
                          </StaffOnlyWrapper>
                        ) : (
                          <DraggableChip label={item.label} Icon={item.icon} />
                        )}
                      </div>
                    );
                  }}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        );
      }}
    </Droppable>
  );
};

interface DraggableChipProps {
  label: string;
  Icon: FC<SVGProps<SVGSVGElement>>;
}

const DraggableChip = ({ label, Icon }: DraggableChipProps) => {
  return (
    <div className='flex h-[32px] items-center gap-1 overflow-hidden rounded-md border border-solid  border-gray-300 bg-white pr-3'>
      <Icons.DragIcon />
      <Icon className='fill-none' />
      <span className='text-caption-2 text-gray-900'>{label}</span>
    </div>
  );
};
