import calculatorIcon from '@iconify/icons-mdi/calculator';
import { Icon } from '@iconify/react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import getUniqueCode from 'actions/unique-code/getUniqueCodeAction';
import { ResourceTypes } from 'api/moduleElement';
import { Trigger } from 'api/resources';
import ImageIcon from 'assets/icons/imageIcon';
import InfoBoxIcon from 'assets/icons/infoboxIcon';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import 'css/custom.css';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { hasViewOnlyPermission } from 'utils/permissions';
import { UIElement } from '../../../api/moduleElement';
import { MODULE_TYPES } from '../../../constants';
import { useTeam } from '../../../hooks/useTeam';
import { Trigger as TriggerComponent } from '../../app-previews/Trigger';
import { VariablesPreview } from '../../app-previews/common/variable';
import ChoicePanelForm from '../../panels/ChoicePanelForm';
import { ModuleContext } from '../../utils/module/ModuleContext';
import { SlidePanel } from '../../utils/panels/SlidePanel';
import { ModuleElementContext } from '../ModuleBoard';

interface ChoiceButtonProps {
  container?: boolean;
}

const ChoiceButton = withStyles((theme) => ({
  root: {
    width: (props: ChoiceButtonProps) => (props.container ? '100%' : '475px'),
    backgroundColor: '#FFFFFF',
    // display: 'grid',
    // position: 'inherit',
    marginLeft: 'auto !important',
    marginRight: 'auto !important',
    marginTop: 'auto !important',
    textAlign: 'left',
    color: '#000000',
    borderRadius: '20px',
    minHeight: '40px',
    wordBreak: 'break-all',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    marginBottom: '8px',
    textTransform: 'none',
    justifyContent: 'flex-start',
    fontSize: 16,
    paddingLeft: '20px',
    lineHeight: '16px',
    '&:hover': {
      backgroundColor: '#62E37D',
      color: 'white',
    },
    child: {
      backgroundColor: 'pink',
    },
  },
  label: {
    padding: '5px',
  },
}))(Button);

export interface FormValues {
  id?: string;
  subtitle: string;
  variable: string;
  container: any;
  choices: any[];
  question: string;
  question_items: any[];
  trigger?: Trigger;
  module?: number;
  calculator?: number;
  position?: number;
  is_dropdown_item: boolean;
}

interface MultipleChoiceCardProps {
  element: UIElement;
  containerId?: number;
}

export const MultipleChoiceCard = ({ element, containerId }: MultipleChoiceCardProps) => {
  const isContainer = !!containerId;
  const { module } = useContext(ModuleContext);
  const { refetchModuleElements, duplicateUIElement, deleteUIElement, clearModuleElements } =
    useContext(ModuleElementContext);
  const [panelOpen, setPanelOpen] = useState(false);
  const [panelModalOpen, setPanelModalOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setPanelOpen(!element.id);
  }, [element]);
  useEffect(() => {
    // TODO: remove when redux data are removed
    dispatch(getUniqueCode(module?.id, module?.type));
  }, []);

  const [deleteModal, setDeleteModal] = useState(false);
  const [duplicateModal, setDuplicateModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const { triggers = [], candidate_triggers = [] } = useSelector(
    (state: any) => state.triggerState || {}
  );

  const triggerList = [...triggers, ...candidate_triggers];

  const defaultValues = useMemo(
    () => ({
      subtitle: element.subtitle ? element.subtitle : '',
      variable: element.variable_item ? element.variable_item.name : '',
      container: element.container ? element.container : undefined,
      choices: element.choices || [
        { sortable_id: 1, name: 'Answer 1', coefficient: 0, position: 0, isSelected: false },
        { sortable_id: 2, name: 'Answer 2', coefficient: 1, position: 1, isSelected: false },
        { sortable_id: 3, name: 'Answer 3', coefficient: 2, position: 2, isSelected: false },
      ],
      question: element.question ? element.question : '',
      question_items: element.question_items ? element.question_items : [],
      trigger: element.trigger ?? undefined,
      module: module?.type === MODULE_TYPES.ALGO ? module.id : undefined,
      calculator: module?.type === MODULE_TYPES.CALCULATOR ? module.id : undefined,
      position: element.position,
      is_dropdown_item: element.is_dropdown_item ? element.is_dropdown_item : false,
    }),
    [element, module]
  );

  const form = useForm<FormValues>({
    // TODO: need to set default trigger id when create mode
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues]);

  const handleDuplicate = (id) => {
    duplicateUIElement({
      elementId: id,
      moduleId: module?.id as number,
      queryParam: ResourceTypes.multipleChoice,
      moduleType: module?.type as MODULE_TYPES,
    });
  };

  const handlePanelClose = () => {
    clearModuleElements();
    form.reset(defaultValues);
    setPanelOpen(false);
  };

  const { team } = useTeam();
  const teamType = team.team_type;

  const [triggerId, setTriggerId] = useState<string | undefined>();
  useEffect(() => {
    if (!!triggerId) {
      form.setValue(
        'trigger',
        triggerList.find((t) => t.id === triggerId)
      );
    }
  }, [triggerId]);

  const [examples, setExamples] = useState<any[]>([]);
  const [tools, setTools] = useState<any[]>([]);
  const questionItem = form.watch('question_items');

  useEffect(() => {
    if (!panelOpen) {
      const tools = questionItem.filter((tool) => tool.section === 'T');
      const examples = questionItem.filter((tool) => tool.section === 'E');

      setTools(tools);
      setExamples(examples);
    }
  }, [panelOpen, questionItem]);

  useEffect(() => {
    if (panelOpen) {
      form.setValue('question_items', [...examples, ...tools]);
    }
  }, [examples, tools, panelOpen]);

  const variable = form.watch('variable');
  const variables = useMemo(
    () => [
      {
        type: 'choice',
        name: variable,
      },
    ],
    [variable]
  );

  // TODO: need refactoring
  //choices trigger element
  let cardStyle = {
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingTop: '10px',
    marginBottom: '0px',
    paddingBottom: '10px',
    width: isContainer ? '100%' : '576px',
    borderRadius: '30px',
    backgroundColor: isContainer ? 'transparent' : 'rgba(255, 255, 255, 0.9)',
    border: 'none',
    boxShadow: isContainer ? 'none' : '0px 4px 25px rgba(0, 0, 0, 0.15)',
  };
  let questionStyle = {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '22px',
    lineHeight: '30px',
    color: '#000000',
    marginBottom: '15px',
  };

  return (
    <>
      <div
        data-testid='choice-panel__test'
        className='!mb-4'
        style={
          {
            position: panelOpen ? 'relative' : '',
            zIndex: panelOpen ? '30' : '',
            maxWidth: isContainer ? '100%' : '580px',
            // marginLeft: this.props.answerForm ? '' : !this.props.container && '25px',
          } as React.CSSProperties
        }
      >
        <TriggerComponent trigger={form.watch('trigger')} containerCard={isContainer} />
        <Card
          className='item-to-hover'
          onDoubleClick={() => !hasViewOnlyPermission(module?.type) && setPanelOpen(true)}
          variant='outlined'
          style={cardStyle}
        >
          <CardContent>
            <Typography component={'span'} style={questionStyle}>
              {!hasViewOnlyPermission(module?.type) && (
                <div
                  onClick={(e) => setAnchorEl(e.target as Element)}
                  style={{
                    background: '#08A88E',
                    width: '33px',
                    height: '33px',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    color: 'white',
                    marginLeft: 'auto',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.11)',
                  }}
                  className='right item-to-show'
                >
                  {' '}
                  <MoreHorizIcon style={{ fontSize: 20 }} />
                </div>
              )}

              <Menu
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id='simple-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem
                  onClick={() => {
                    setPanelOpen(true);
                    setAnchorEl(null);
                  }}
                >
                  Edit
                </MenuItem>
                {teamType !== 'A' && (
                  <MenuItem
                    onClick={() => {
                      setDeleteModal(true);
                      setAnchorEl(null);
                    }}
                  >
                    Delete
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    setDuplicateModal(true);
                    setAnchorEl(null);
                  }}
                >
                  Duplicate
                </MenuItem>
              </Menu>

              {form.watch('question')}
            </Typography>
            <div className='mb-[28px]'></div>
            <Typography
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '16px',
                lineHeight: '21px',
                color: '#ADADAD',
              }}
            >
              {form.watch('subtitle')}
            </Typography>

            {examples.length > 0 && (
              <Box
                style={{
                  border: '0.75px solid #B3B3B3',
                  boxSizing: 'border-box',
                  borderRadius: '10px',
                  minHeight: '58px',
                  marginTop: 12,
                  marginBottom: 5,
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: 15,
                }}
              >
                {examples.map((example, index) => {
                  return !!example.text ? (
                    <Typography
                      style={{
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: '16px',
                        lineHeight: '16px',
                        color: '#000000',
                      }}
                    >
                      {example.label || example.text?.statement}
                      {examples[index + 1] && <span style={{ marginRight: 4 }}>,</span>}
                    </Typography>
                  ) : !!example.infobox ? (
                    <Typography
                      style={{
                        backgroundColor: 'transparent',
                        color: ' #18C16E',
                        fontSize: '16px',
                        marginRight: 6,
                      }}
                    >
                      {example.label || example.infobox?.shortened_title}
                      {examples[index + 1] && ','}
                    </Typography>
                  ) : !!example.image ? (
                    <Typography
                      style={{
                        backgroundColor: 'transparent',
                        color: ' #18C16E',
                        fontSize: '16px',
                        marginRight: 6,
                      }}
                    >
                      {example.label || example.image?.representation_phrase}
                      {examples[index + 1] && ','}
                    </Typography>
                  ) : null;
                })}
              </Box>
            )}

            {tools.map((tool, index) => {
              return !!tool.infobox ? (
                <Chip
                  style={{
                    marginTop: '13px',
                    marginBottom: '5px',
                    backgroundColor: 'transparent',
                    color: '#08A88E',
                    fontSize: '16px',
                    alignItems: 'center',
                    fontStyle: 'normal',
                    fontWeight: 600,

                    lineHeight: '22px',
                  }}
                  size='small'
                  key={index}
                  icon={<InfoBoxIcon />}
                  label={tool.label || tool.infobox?.shortened_title}
                />
              ) : !!tool.image ? (
                <Chip
                  style={{
                    marginTop: '13px',
                    marginBottom: '5px',
                    backgroundColor: 'transparent',
                    color: '#08A88E',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    alignItems: 'center',
                    lineHeight: '22px',
                  }}
                  size='small'
                  key={index}
                  icon={<ImageIcon />}
                  label={tool.label || tool.image?.representation_phrase}
                />
              ) : !!tool.legacy_calculator ? (
                <Chip
                  style={{
                    marginTop: '13px',
                    marginBottom: '5px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    backgroundColor: 'transparent',
                    color: '#08A88E',
                    alignItems: 'center',
                    fontSize: '16px',
                    lineHeight: '22px',
                  }}
                  size='small'
                  key={index}
                  icon={<Icon icon={calculatorIcon} />}
                  label={tool.label || tool.legacy_calculator?.title}
                />
              ) : !!tool.calculator ? (
                <Chip
                  style={{
                    marginTop: '13px',
                    marginBottom: '5px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    backgroundColor: 'transparent',
                    color: '#08A88E',
                    alignItems: 'center',
                    fontSize: '16px',
                    lineHeight: '22px',
                  }}
                  size='small'
                  key={index}
                  icon={<Icon icon={calculatorIcon} />}
                  label={tool.label || tool.calculator?.name}
                />
              ) : null;
            })}

            {form.watch('choices').map((choice, index) => (
              <div key={index} style={{ marginTop: 12 }}>
                <ChoiceButton container={isContainer}>{choice.name}</ChoiceButton>
                {choice.mapped_output_categories &&
                  choice.mapped_output_categories.map((output_category, index) => (
                    <Chip
                      key={index}
                      style={{
                        backgroundColor: '#08A88E',
                        color: 'white',
                        marginLeft: '22px',
                      }}
                      size='small'
                      label={output_category}
                    />
                  ))}
              </div>
            ))}
          </CardContent>
        </Card>

        {!isContainer && <VariablesPreview variables={variables} />}

        <ConfirmModal
          preset='delete'
          open={deleteModal}
          content='Are you sure you want to delete this card?'
          toggleModal={() => setDeleteModal(false)}
          performAction={() => {
            deleteUIElement(element.id);
            setDeleteModal(false);
          }}
        />
        <ConfirmModal
          preset='duplicate'
          open={duplicateModal}
          content='Are you sure you want to duplicate this card?'
          toggleModal={() => setDuplicateModal(false)}
          performAction={() => {
            handleDuplicate(element.id);
            setDuplicateModal(false);
          }}
        />
      </div>
      <SlidePanel open={panelOpen} onClose={() => setPanelModalOpen(true)}>
        <div className='w-[620px] overflow-x-visible bg-white'>
          <ChoicePanelForm
            handleSelectedItem={() => {}}
            handleLivePreview={() => {}}
            resetAnswerPages={() => {}}
            container={containerId}
            handleAddChoices={(choices: any) => form.setValue('choices', choices)}
            handleAddVariable={(variable: string) => form.setValue('variable', variable)}
            handleAddQuestion={(question: string) => form.setValue('question', question)}
            handleAddExample={(examples: any[]) => setExamples(examples)}
            handleAddSubtitle={(subtitle: string) => form.setValue('subtitle', subtitle)}
            handleAddTools={(tools: any[]) => setTools(tools)}
            handleAddTrigger={(triggerId) => setTriggerId(triggerId)}
            moduleId={module?.type === MODULE_TYPES.ALGO ? module?.id : undefined}
            calculatorId={module?.type === MODULE_TYPES.CALCULATOR ? module?.id : undefined}
            moduleCode={module?.code}
            position={element.position}
            toggleModal={handlePanelClose}
            choiceId={element.id}
            resetState={refetchModuleElements}
            modal={panelModalOpen}
            closeUiElementPane={() => setPanelModalOpen(false)}
          />
        </div>
      </SlidePanel>
    </>
  );
};
