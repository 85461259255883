import { MenuItem } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import { useEffect, useState } from 'react';
import { MODULE_TYPES } from '../../../constants';
import { APIResourceAuthorization, APIResourceBody } from './APIResourceBody';
import { flattenOptions, formatJson, generateOptions } from './APIResourceUtil';

export const APIResourceForm = ({ moduleId, resource, onSave, onCancel, moduleType }) => {
  const [formData, setFormData] = useState({
    title: '',
    url: 'https://api.example.com',
    method: 'GET',
    authorization: '',
    content_type: '',
    body: '',
    output_type: 'object',
    output_type_definition: '{ "input your key here": "input your value here" }',
    output_you_want_to_use: '',
    module: moduleType === MODULE_TYPES.ALGO ? moduleId : undefined,
    calculator: moduleType === MODULE_TYPES.CALCULATOR ? moduleId : undefined,
  });
  const [isValidJson, setIsValidJson] = useState(true);

  useEffect(() => {
    if (resource) {
      setFormData(resource);
    }
  }, [resource]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleAuthorizationChange = (e) => {
    setFormData({
      ...formData,
      authorization: e,
    });
  };

  const handleBodyChange = (e) => {
    setFormData({
      ...formData,
      body: e,
    });
  };

  const handleOutputTypeDefinitionChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    try {
      JSON.parse(value);
      setIsValidJson(true);
    } catch (error) {
      setIsValidJson(false);
    }
  };

  const handleKeySelect = (e) => {
    const { value } = e.target;

    setFormData({
      ...formData,
      output_you_want_to_use: value,
    });
  };

  const handleSave = () => {
    onSave(formData);
  };

  let outputTypeOptions: any[] = [];
  if (isValidJson) {
    const parsedOutputTypeDefinition = JSON.parse(formData.output_type_definition);
    outputTypeOptions = generateOptions(parsedOutputTypeDefinition);
  }

  return (
    <div className='mb-4'>
      <label className='block text-black'>
        Title:
        <input
          type='text'
          name='title'
          value={formData.title}
          onChange={handleChange}
          className='ml-1 border border-gray-300 p-1'
        />
      </label>

      <label className='mt-4 block text-black'>
        URL:
        <input
          type='text'
          name='url'
          value={formData.url}
          onChange={handleChange}
          className='ml-1 border border-gray-300'
        />
      </label>

      <label className='mt-4 block text-black'>
        Content Type:
        <Select
          name='content_type'
          value={formData.content_type}
          onChange={handleChange}
          className='ml-1 border border-gray-300'
        >
          <option value=''>None</option>
          <option value='application/json'>application/json</option>
          <option value='application/xml'>application/xml</option>
          <option value='text/plain'>text/plain</option>
          <option value='text/html'>text/html</option>
          <option value='application/x-www-form-urlencoded'>
            application/x-www-form-urlencoded
          </option>
        </Select>
      </label>

      <label className='mt-4 block text-black'>Body:</label>
      <APIResourceBody initBody={formData.body} onChange={handleBodyChange} />

      <label className='mt-4 block text-black'>
        Method:
        <Select
          name='method'
          value={formData.method}
          onChange={handleChange}
          className='ml-1 border border-gray-300'
        >
          <option value='GET'>GET</option>
          <option value='POST'>POST</option>
          <option value='PUT'>PUT</option>
          <option value='DELETE'>DELETE</option>
        </Select>
      </label>

      <label className='mt-4 block text-black'>
        Authorization:
        <APIResourceAuthorization
          initBody={formData.authorization}
          onChange={handleAuthorizationChange}
        />
      </label>

      <label className='mt-4 block text-black'>
        Output Type:
        <Select
          name='output_type'
          value={formData.output_type}
          onChange={handleChange}
          className='ml-1 border border-gray-300'
        >
          <option value='string'>String</option>
          <option value='object'>Object</option>
        </Select>
      </label>

      <label className='mt-4 block text-black'>
        Output Type Definition (JSON):
        <textarea
          name='output_type_definition'
          value={
            isValidJson
              ? formatJson(formData.output_type_definition)
              : formData.output_type_definition
          }
          onChange={handleOutputTypeDefinitionChange}
          style={{ minHeight: '200px' }} // as below tailwind not working
          className='min-h-100 ml-1 resize-none border border-gray-300 p-2'
        />
        {!isValidJson && (
          <p className='mt-2 text-red-500'>Invalid JSON syntax. Please enter valid JSON.</p>
        )}
      </label>

      <label className='mt-4 block text-black'>
        Output You Want to Use:
        <Select
          name='output_you_want_to_use'
          value={formData.output_you_want_to_use}
          onChange={handleKeySelect}
          className='ml-1 border border-gray-300 p-2'
        >
          <MenuItem value=''>Select a key</MenuItem>
          {flattenOptions(outputTypeOptions).map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.value}
            </MenuItem>
          ))}
        </Select>
        <p className='mt-2 text-gray-500'>
          Selected Key: {formData.output_you_want_to_use || 'No key selected'}
        </p>
      </label>

      <button onClick={handleSave} className='mr-4 mt-4 rounded bg-green-500 px-4 py-2 text-white'>
        Save
      </button>
      <button onClick={onCancel} className='mt-4 rounded bg-red-500 px-4 py-2 text-white'>
        Cancel
      </button>
    </div>
  );
};
