import { Box, Menu, MenuList, Typography, withStyles } from '@material-ui/core';
import { KeyboardArrowDownOutlined } from '@material-ui/icons';
import SearchIcon from 'assets/icons/searchIcon';
import TextIcon from 'assets/icons/textIcon';
import { StyledMenuItem } from 'components/module-detail/container/styles';
import { InfoboxLabelChangeButton } from 'components/utils/styled-components/FormStyle';
import { StaffOnlyWrapper, StaffOnlyBadgeType } from 'components/utils/StaffOnlyWrapper';
import { CustomToast } from 'components/utils/toast-message';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { toolbarStyles } from './styles';
import Label from '../Label';
import Checkbox from '../Checkbox';
import {
  NOTE_CUSTOM_RANGE_CODE,
  LAB_DATA_CUSTOM_RANGE_CODE,
  VITAL_SIGN_CUSTOM_RANGE_CODE,
  NOTE_TYPE,
  LAB_DATA_TYPE,
  VITAL_SIGN_TYPE,
  MEDICATION_TYPE,
  PROBLEM_LIST_CODE,
} from '../../../hooks/useEHRVariables';
import { withAuthentication } from '../../../hooks/useAuthentication';
import {
  DEFAULT_AMOUNT_BY_TYPES,
  MAXIMUM_AMOUNT_BY_TYPES,
  NumberInput,
  READABLE_EHR_TYPES,
} from '../tiptap/tiptap-expand-options/suggestion-handler/EHRDataHandler';

class SearchableDropdown extends Component {
  constructor(props) {
    super(props);

    const { items = [] } = props;
    const type = items.length && items[0].hasOwnProperty('type') ? items[0]['type'] : '';

    this.state = {
      type,
      roundingOptionsEl: null,
      decimalPlacesEl: null,
      searchValue: '',
      textToDisplay: '',
      selectedItem: '',
      decimalPlaces: 1,
      roundingOption: 'ceil',
      hasUnsignedNote: false,
      days: undefined,
      amount: undefined,
      past24HoursExcluded: false,
    };
  }

  componentDidMount() {
    const decimalPlaces =
      this.props.selectedItem?.decimalPlaces === undefined ||
      this.props.selectedItem?.decimalPlaces === null
        ? 1
        : this.props.selectedItem?.decimalPlaces;

    let selectedItem;
    if (this.props.selectedItem) {
      selectedItem = {
        title: this.props.selectedItem?.name,
        unique_code: this.props.selectedItem?.code,
        type: this.props.selectedItem?.type,
      };
    }

    this.setState({
      textToDisplay: this.props.text || '',
      roundingOption: this.props.selectedItem?.decimalRounding || 'ceil',
      selectedItem,
      decimalPlaces,
      amount: DEFAULT_AMOUNT_BY_TYPES[this.state.type],
    });
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  setDecimalPlaces = (decimalPlaces) => {
    this.setState({ decimalPlaces, decimalPlacesEl: null });
  };

  setRoundingOption = (roundingOption) => {
    this.setState({ roundingOption, roundingOptionsEl: null });
  };

  setHasUnsignedNote = (hasUnsignedNote) => {
    this.setState({ hasUnsignedNote });
  };

  setPast24HoursExcluded = (past24HoursExcluded) => {
    this.setState({ past24HoursExcluded });
  };

  selectItem = (item) => {
    this.setState({ selectedItem: item });
    this.setState({ textToDisplay: item.title });

    if (
      [NOTE_CUSTOM_RANGE_CODE, LAB_DATA_CUSTOM_RANGE_CODE, VITAL_SIGN_CUSTOM_RANGE_CODE].includes(
        item.unique_code
      )
    ) {
      this.setState({ days: undefined });
    }
  };

  addItem = () => {
    if (
      [NOTE_CUSTOM_RANGE_CODE, LAB_DATA_CUSTOM_RANGE_CODE, VITAL_SIGN_CUSTOM_RANGE_CODE].includes(
        this.state.selectedItem?.unique_code
      ) &&
      !this.state.days
    ) {
      toast.error(CustomToast, { data: 'Please add custom range' });
      return;
    }
    if (!this.state.textToDisplay) {
      toast.error(CustomToast, { data: 'Please add label' });
      return;
    } else if (!this.state.selectedItem) {
      toast.error(CustomToast, { data: 'Please select an entity' });
      return;
    }

    let obj = {
      code: this.state.selectedItem?.unique_code,
      name: this.state.textToDisplay,
    };

    if (this.props.isVariable) {
      obj.type = this.state.selectedItem?.type;
    } else {
      obj.decimalPlaces = this.state.decimalPlaces;
      obj.decimalRounding = this.state.roundingOption;
    }
    if (this.state.type === 'note') {
      obj.hasUnsignedNote = this.state.hasUnsignedNote;
      obj.past24HoursExcluded = this.state.past24HoursExcluded;
    }
    if (
      [NOTE_CUSTOM_RANGE_CODE, LAB_DATA_CUSTOM_RANGE_CODE, VITAL_SIGN_CUSTOM_RANGE_CODE].includes(
        this.state.selectedItem?.unique_code
      )
    ) {
      obj.days = this.state.days;
    }
    obj.amount = this.state.amount;

    this.props.addItem(
      obj.type === 'ehr_order'
        ? this.props.items.find((item) => obj.code === item.unique_code)
        : obj
    );
  };

  render() {
    const { classes } = this.props;
    let items = this.props.items || [];
    let filteredItems = [];
    items.forEach((obj) => {
      let title = obj.title || obj.shortened_name || obj.name || '';
      if (title.toLowerCase().includes(this.state.searchValue.toLowerCase())) {
        filteredItems.push({
          id: obj.id,
          title: title,
          unique_code: obj.unique_code || obj.code,
          type: obj.type,
        });
      }
    });
    const isConfirmDisabled =
      [NOTE_TYPE, LAB_DATA_TYPE, VITAL_SIGN_TYPE, MEDICATION_TYPE].includes(
        this.state.selectedItem?.type
      ) &&
      (([NOTE_CUSTOM_RANGE_CODE, LAB_DATA_CUSTOM_RANGE_CODE, VITAL_SIGN_CUSTOM_RANGE_CODE].includes(
        this.state.selectedItem?.unique_code
      ) &&
        (!this.state.days || this.state.days > 1095)) ||
        !this.state.amount ||
        this.state.amount > MAXIMUM_AMOUNT_BY_TYPES[this.state.type]) &&
      PROBLEM_LIST_CODE !== this.state.selectedItem?.unique_code;

    return (
      <div>
        <Box
          className={classes.variableMenuContainer}
          style={{
            paddingBottom: this.props.isVariable ? 5 : 16,
            marginBottom: filteredItems?.length > 0 && '10px',
          }}
        >
          <div className={classes.variableSearchContainer}>
            <input
              autoFocus
              type='text'
              name='searchValue'
              placeholder='Search...'
              className={classes.input}
              value={this.state.searchValue}
              onChange={this.onChange}
            />
            <SearchIcon />
          </div>
          <div className={classes.variableSearchContainer}>
            <input
              type='text'
              name='textToDisplay'
              className={classes.input}
              placeholder='Text to display'
              value={this.state.textToDisplay}
              onChange={this.onChange}
              data-testid='display-text'
            />
            <TextIcon />
          </div>
          {!!this.props.items &&
            this.props.items.some((item) =>
              [NOTE_TYPE, LAB_DATA_TYPE, VITAL_SIGN_TYPE, MEDICATION_TYPE].includes(item.type)
            ) && (
              <StaffOnlyWrapper type={StaffOnlyBadgeType.BETA} y={-10}>
                <div className='mb-4'>
                  <NumberInput
                    prefixLabel='Maximum'
                    postfixLabel={READABLE_EHR_TYPES[this.state.type]}
                    value={this.state.amount}
                    onChange={(amount) =>
                      this.setState({
                        amount: amount,
                      })
                    }
                    maximumValue={MAXIMUM_AMOUNT_BY_TYPES[this.state.type]}
                    placeholder='number'
                    errorLabel={`*The maximum number of ${READABLE_EHR_TYPES[this.state.type]} is ${
                      MAXIMUM_AMOUNT_BY_TYPES[this.state.type]
                    }.`}
                  />
                </div>
              </StaffOnlyWrapper>
            )}
          {[
            NOTE_CUSTOM_RANGE_CODE,
            LAB_DATA_CUSTOM_RANGE_CODE,
            VITAL_SIGN_CUSTOM_RANGE_CODE,
          ].includes(this.state.selectedItem?.unique_code) && (
            <div className='mb-4'>
              <NumberInput
                prefixLabel='Last'
                postfixLabel='days'
                value={this.state.days}
                onChange={(amount) =>
                  this.setState({
                    days: amount,
                  })
                }
                maximumValue={1095}
                placeholder='number'
                errorLabel='*The Maximum number of days is 1095.'
              />
            </div>
          )}
          {this.state.type === 'note' && (
            <>
              <Box className={classes.showBox}>
                <Label className='flex cursor-pointer items-center space-x-[8px]'>
                  <Checkbox
                    checked={this.state.hasUnsignedNote}
                    onChange={(e) => this.setHasUnsignedNote(e.target.checked)}
                  />
                  <span className='text-sm text-gray-900'>Include unsigned notes</span>
                </Label>
              </Box>
              <Box className={classes.showBox}>
                <Label className='flex cursor-pointer items-center space-x-[8px]'>
                  <Checkbox
                    checked={this.state.past24HoursExcluded}
                    onChange={(e) => this.setPast24HoursExcluded(e.target.checked)}
                  />
                  <span className='text-sm text-gray-900'>Exclude &lt; 24 hours notes</span>
                </Label>
              </Box>
            </>
          )}
          {!this.props.isVariable && (
            <Box className={classes.showBox}>
              <Typography className={classes.optionsLabel}> Show </Typography>
              <Typography
                data-testid='decimal-option'
                className={classes.selectedOption}
                onClick={(e) => this.setState({ decimalPlacesEl: e.target })}
              >
                {this.state.decimalPlaces === 1
                  ? '1 Decimal'
                  : this.state.decimalPlaces === 2
                  ? '2 Decimal'
                  : this.state.decimalPlaces === 0
                  ? 'Integer'
                  : 'Error'}
                <KeyboardArrowDownOutlined />
              </Typography>
              <Typography className={classes.optionsLabel}> Rounded </Typography>
              <Typography
                data-testid='rounding-option'
                className={classes.selectedOption}
                onClick={(e) => this.setState({ roundingOptionsEl: e.target })}
              >
                {this.state.roundingOption === 'floor'
                  ? 'Down'
                  : this.state.roundingOption === 'ceil'
                  ? 'Up'
                  : this.state.roundingOption === 'round'
                  ? 'Round'
                  : 'Error'}
                <KeyboardArrowDownOutlined />
              </Typography>
            </Box>
          )}
        </Box>
        <Menu
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          getContentAnchorEl={null}
          anchorEl={this.state.decimalPlacesEl}
          open={Boolean(this.state.decimalPlacesEl)}
          onClose={() => this.setState({ decimalPlacesEl: null })}
        >
          <StyledMenuItem onClick={() => this.setDecimalPlaces(0)}>Integer</StyledMenuItem>
          <StyledMenuItem onClick={() => this.setDecimalPlaces(1)}> 1 Decimal </StyledMenuItem>
          <StyledMenuItem onClick={() => this.setDecimalPlaces(2)}> 2 Decimal </StyledMenuItem>
        </Menu>

        <Menu
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          getContentAnchorEl={null}
          anchorEl={this.state.roundingOptionsEl}
          open={Boolean(this.state.roundingOptionsEl)}
          onClose={() => this.setState({ roundingOptionsEl: null })}
        >
          <StyledMenuItem onClick={() => this.setRoundingOption('ceil')}>Up</StyledMenuItem>
          <StyledMenuItem onClick={() => this.setRoundingOption('floor')}> Down </StyledMenuItem>
          <StyledMenuItem onClick={() => this.setRoundingOption('round')}>
            {' '}
            Rounding{' '}
          </StyledMenuItem>
        </Menu>

        <MenuList autoFocus>
          {filteredItems.map((item) => (
            <StyledMenuItem
              key={item.id}
              className={classes.menuItem}
              onClick={() => this.selectItem(item)}
              style={{
                color:
                  item.unique_code === this.state.selectedItem?.unique_code ? '#08A88E' : '#172B4D',
              }}
            >
              {item.title}
            </StyledMenuItem>
          ))}
        </MenuList>
        <div className={classes.variableConfirmBtn}>
          <InfoboxLabelChangeButton onMouseDown={this.addItem} disabled={isConfirmDisabled}>
            Confirm
          </InfoboxLabelChangeButton>
        </div>
      </div>
    );
  }
}

export default withStyles(toolbarStyles)(withAuthentication(SearchableDropdown));
