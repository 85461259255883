import clsx from 'clsx';
import { ModuleHeader } from 'components/bars/ModuleHeader';
import { ModuleSidebar } from 'components/bars/ModuleSidebar';
import { globalStyles } from 'components/utils/bootsrap-and-materialize';
import { ModuleProvider } from 'components/utils/module/ModuleContext';
import { createContext, HTMLAttributes, useState } from 'react';
import { useParams } from 'react-router';
import { MODULE_TYPES } from '../../constants';
import { UiElementProvider } from '../utils/module/UiElementContext';
import { ConvertingButton } from '../utils/tiptap/converter/converting-button/ConvertingButton';

interface ModuleLayoutProps extends HTMLAttributes<HTMLDivElement> {}

interface ModuleLayoutParams {
  moduleType: 'algo' | 'calculator';
  moduleId: string;
}

// TODO: remove it and related logic after integrate all draft content to tiptap
export const ConvertContext = createContext(false);

export const ModuleLayout = ({ children, className }: ModuleLayoutProps) => {
  const { moduleType } = useParams<ModuleLayoutParams>();
  const [isConverted, setIsConverted] = useState(false);
  return (
    <ModuleProvider moduleType={moduleType as MODULE_TYPES}>
      <UiElementProvider>
        <ConvertContext.Provider value={isConverted}>
          {/* need to remove after refactoring all module related components */}
          {globalStyles}
          {/*  */}
          <div className='flex h-screen flex-col'>
            <ModuleHeader className='!relative' />
            <div className='flex grow overflow-hidden'>
              <div className='min-w-[164px] overflow-y-auto shadow-md'>
                <div className='flex h-full flex-col justify-between'>
                  <ModuleSidebar />
                  <ConvertingButton
                    isConverted={isConverted}
                    setIsConverted={(bool: boolean) => setIsConverted(bool)}
                  />
                </div>
              </div>
              <div className={clsx('h-full grow overflow-y-auto', className)}>{children}</div>
            </div>
          </div>
        </ConvertContext.Provider>
      </UiElementProvider>
    </ModuleProvider>
  );
};
