import { MapEntityToNodeFn } from '@tiptap/draftjs-to-tiptap';
import { MentionData, MentionType } from '../types';

/**
 * If DraftJS's mention data is added/edited, please change this part as well
 */
export const avoEntityToNodeMapping = function (mention: MentionData) {
  switch (mention.type) {
    case MentionType.knowledge_base:
    case MentionType.calculator:
    // TODO: check whether type is 'algo' or 'module'
    case MentionType.module:
      return {
        // id is in tiptap but not in draftJS
        id: undefined,
        label: null,
        ...mention,
      };
    case MentionType.unembedded:
    case MentionType.embedded:
      return {
        href: mention.code,
        title: mention.name,
      };
    case MentionType.note:
    case MentionType.progress_note:
    case MentionType.lab_data:
    case MentionType.vital_sign:
    case MentionType.medication:
    case MentionType.problem_list:
      const { aliasName, ...attrs } = mention;
      return {
        label: null,
        ...attrs,
      };
    case MentionType.ehr_order:
      return {
        id: null,
        label: null,
        ...mention,
      };
    case MentionType.message_variable:
    case MentionType.multi_variable:
    case MentionType.gptbox_variable:
    case MentionType.ambient_variable:
    case MentionType.text_input_variable:
      return {
        // id is in tiptap but not in draftJS
        id: undefined,
        label: null,
        ...mention,
      };
    case MentionType.data_extractor:
      return { ...mention };
  }
  if (
    ['VARIABLES', 'INFOBOXES', 'REFERENCES', 'FORMULAE', 'NUMERICS', 'image'].includes(mention.name)
  ) {
    return null;
  } else if (mention.code.includes('reference')) {
    return {
      // id is in tiptap but not in draftJS
      id: undefined,
      label: null,
      type: 'reference',
      ...mention,
    };
  } else if (mention.code.includes('formula')) {
    return {
      // id is in tiptap but not in draftJS
      id: undefined,
      label: null,
      type: 'formula',
      ...mention,
    };
  } else if (mention.code.includes('customnumeric')) {
    return {
      // id is in tiptap but not in draftJS
      id: undefined,
      label: null,
      ...mention,
    };
  } else if (mention.code.includes('numeric')) {
    return {
      // id is in tiptap but not in draftJS
      id: undefined,
      label: null,
      type: 'number',
      ...mention,
    };
  } else if (mention.code.includes('variable')) {
    return {
      // id is in tiptap but not in draftJS
      id: undefined,
      label: null,
      ...mention,
    };
  } else if (mention.code === 'phoneNumber') {
    return {
      id: null,
      label: null,
      type: 'phone_number',
      ...mention,
    };
  } else if (mention.code.includes('conditionaltext')) {
    return {
      // id is in tiptap but not in draftJS
      id: undefined,
      label: null,
      type: 'conditional_text',
      ...mention,
    };
  } else if (mention.code.includes('infobox')) {
    return {
      // id is in tiptap but not in draftJS
      id: undefined,
      name: mention.name,
      code: mention.code,
      type: 'infobox',
    };
  }
};

export const mapAvoEntityToNode: MapEntityToNodeFn | any = function ({
  range: { key },
  entityMap,
}) {
  const targetEntity = entityMap[key].data.mention;
  return avoEntityToNodeMapping(targetEntity);
};
